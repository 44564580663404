<script>
import Blocker from './utilities/Blocker'
import CloseIcon from '@/components/icons/IconsX.vue'

export default {
  name: 'RoomTour',
  components: {
    CloseIcon,
    Blocker,
  },

  props: ['tourUrl'],
  data() {
    return {
      showModal: false,
      viewWidth: 640,
      viewHeight: 640,
    }
  },
  mounted() {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    )
    if (vw)
      this.viewWidth = Math.round(vw * 0.75)

    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0,
    )
    if (vh)
      this.viewHeight = Math.round(vh * 0.75)
  },
}
</script>

<template>
  <div>
    <div class="cursor-pointer" @click="showModal = true">
      <slot />
    </div>

    <Teleport to="#portal-destination">
      <div v-if="showModal" key="learnmore" class="fixed inset-0 z-100">
        <Blocker @click="showModal = false" />
        <div
          class="bg-white p-4 fixed z-50 rounded shadow-lg wysiwyg mx-2 my-4 overflow-scroll"
          style="
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            max-height: 90%;
          "
        >
          <div
            class="absolute top-0 right-0 mt-4 mr-4 cursor-pointer"
            @click="showModal = false"
          >
            <CloseIcon />
          </div>
          <iframe
            :width="viewWidth"
            height="viewHeight"
            style="border: none; max-height: 640px"
            frameborder="0"
            allowfullscreen
            allow="xr-spatial-tracking; gyroscope; accelerometer"
            scrolling="no"
            :style="{
              width: `${viewWidth}px`,
              height: `${viewHeight}px`,
            }"
            :src="tourUrl"
          />
        </div>
      </div>
    </Teleport>
  </div>
</template>
